import { User } from 'app/shared/common/models/user';
import { UserRole } from '../models/enums/user-role/user.role';

export class UserRoleUtils {
  public static readonly USER_ROLES: UserRoleInfo[] = [
    {
      key: UserRole.DICON_OPERATOR,
      label: 'Steuerungsstelle',
      tokenNames: ['DiconOperator', 'DiconDevOperator', 'DiconStageOperator', 'DiconProdOperator'],
      prio: 1,
    },
    {
      key: UserRole.DICON_OPERATOR_EXTERN,
      label: 'Partner',
      tokenNames: ['DiconOperatorExtern', 'DiconDevOperatorExtern', 'DiconStageOperatorExtern', 'DiconProdOperatorExtern'],
      prio: 2,
    },
    {
      key: UserRole.DICON_USER,
      label: 'Besucher',
      tokenNames: ['DiconUser', 'DiconDevUser', 'DiconStageUser', 'DiconProdUser'],
      prio: 3,
    },
    {
      key: UserRole.UNKNOWN,
      label: 'Unbekannte Rolle',
      tokenNames: ['UNKNOWN'],
      prio: 99,
    },
  ];

  public static getUserRoleInfo(userRole: string | UserRole): UserRoleInfo {
    const element: UserRoleInfo = UserRoleUtils.USER_ROLES.find(elem => elem.key === userRole);
    return !element ? null : element;
  }

  public static getUserRoleLabel(userRole: string | UserRole): string {
    const element: UserRoleInfo = UserRoleUtils.getUserRoleInfo(userRole);
    return !element ? '' : element.label;
  }

  public static getUserRolesFromToken(tokenRoles: string): UserRole[] {
    const userRoles: UserRole[] = [];
    let rolesArray: string[] = [];

    try {
      rolesArray = tokenRoles ? JSON.parse(tokenRoles) : [];
    } catch (e) {
      console.error('Failed to parse tokenRoles:', e);
      return userRoles;
    }

    if (rolesArray.length === 0) {
      return userRoles;
    }

    UserRoleUtils.USER_ROLES.forEach(userRoleInfo => {
      if (!userRoleInfo.tokenNames?.length) {
        return;
      }

      userRoleInfo.tokenNames.forEach(tokenName => {
        if (rolesArray.includes(tokenName)) {
          userRoles.push(userRoleInfo.key);
        }
      });
    });

    return userRoles;
  }

  public static determinePrimaryUserRoleInfo(userRoles: UserRole[]): UserRoleInfo {
    if (!userRoles?.length) {
      return null;
    }
    let userRoleInfo: UserRoleInfo = UserRoleUtils.getUserRoleInfo(UserRole.UNKNOWN);
    userRoles.forEach(userRole => {
      const currentUserRoleInfo: UserRoleInfo = UserRoleUtils.getUserRoleInfo(userRole);
      if (!currentUserRoleInfo || currentUserRoleInfo?.prio >= userRoleInfo.prio) {
        return;
      }
      userRoleInfo = currentUserRoleInfo;
    });
    return userRoleInfo;
  }

  public static hasRoleOperatorOrOperatorExternOrUser(user: User): boolean {
    return (
      UserRoleUtils.hasRole(user, UserRole.DICON_OPERATOR) ||
      UserRoleUtils.hasRole(user, UserRole.DICON_OPERATOR_EXTERN) ||
      UserRoleUtils.hasRole(user, UserRole.DICON_USER)
    );
  }

  public static hasRoleOperatorExternOrUser(user: User): boolean {
    return (
      UserRoleUtils.hasRole(user, UserRole.DICON_OPERATOR_EXTERN) ||
      UserRoleUtils.hasRole(user, UserRole.DICON_USER)
    );
  }

  public static hasRoleOperator(user: User): boolean {
    return UserRoleUtils.hasRole(user, UserRole.DICON_OPERATOR);
  }

  public static hasRoleOperatorExtern(user: User): boolean {
    return UserRoleUtils.hasRole(user, UserRole.DICON_OPERATOR_EXTERN);
  }

  public static hasRoleUser(user: User): boolean {
    return UserRoleUtils.hasRole(user, UserRole.DICON_USER);
  }

  public static hasRole(user: User, role: UserRole): boolean {
    return !!user
      && !!role
      && !!user.roles?.length
      && user.roles.includes(role);
  }

  public static getUserId(user: User): string {
    return !user.email ? user.id : user.email;
  }
}

export interface UserRoleInfo {
  key: UserRole;
  label: string;
  tokenNames: string[];
  prio: number;
}
