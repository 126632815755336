/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ApprovalStateTOV2 } from '../model/approvalStateTOV2';
// @ts-ignore
import { FaultReport } from '../model/faultReport';
// @ts-ignore
import { FaultReport1 } from '../model/faultReport1';
// @ts-ignore
import { FaultReportDocumentTO } from '../model/faultReportDocumentTO';
// @ts-ignore
import { FaultReportList } from '../model/faultReportList';
// @ts-ignore
import { FaultReportSummary } from '../model/faultReportSummary';
// @ts-ignore
import { FeatureCollectionV2 } from '../model/featureCollectionV2';
// @ts-ignore
import { GeoLocationResponseV2ControlCabinetLocationDataV2 } from '../model/geoLocationResponseV2ControlCabinetLocationDataV2';
// @ts-ignore
import { GeoLocationResponseV2LightingPointLocationDataV2 } from '../model/geoLocationResponseV2LightingPointLocationDataV2';
// @ts-ignore
import { IncidentDocumentCollectionTO } from '../model/incidentDocumentCollectionTO';
// @ts-ignore
import { IncidentDocumentTO } from '../model/incidentDocumentTO';
// @ts-ignore
import { IncidentNotification } from '../model/incidentNotification';
// @ts-ignore
import { IncidentStatus } from '../model/incidentStatus';
// @ts-ignore
import { Priority } from '../model/priority';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class FaultReportService {

    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Upload Fault Report Documents for an Incident
     * Allows the uploading of documents associated with a specific incident, identified by its unique ID. Supported document types are PDF, PNG, JPEG, and Microsoft Outlook files (application/pdf, image/png, image/jpeg, application/vnd.ms-outlook).
     * @param id Incident UUID
     * @param customerScope AGS or ZV
     * @param faultReportDocumentTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDocumentByIdV2(id: string, customerScope: string, faultReportDocumentTO?: Array<FaultReportDocumentTO>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<IncidentDocumentTO>>;
    public createDocumentByIdV2(id: string, customerScope: string, faultReportDocumentTO?: Array<FaultReportDocumentTO>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<IncidentDocumentTO>>>;
    public createDocumentByIdV2(id: string, customerScope: string, faultReportDocumentTO?: Array<FaultReportDocumentTO>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<IncidentDocumentTO>>>;
    public createDocumentByIdV2(id: string, customerScope: string, faultReportDocumentTO?: Array<FaultReportDocumentTO>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling createDocumentByIdV2.');
        }
        if (customerScope === null || customerScope === undefined) {
            throw new Error('Required parameter customerScope was null or undefined when calling createDocumentByIdV2.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/faultreports/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/documents`;
        return this.httpClient.request<Array<IncidentDocumentTO>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: faultReportDocumentTO,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new FaultReport
     * Create a new FaultReport.
     * @param customerScope AGS or ZV
     * @param faultReport 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createFaultReportV2(customerScope: string, faultReport?: FaultReport, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<FaultReport1>;
    public createFaultReportV2(customerScope: string, faultReport?: FaultReport, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<FaultReport1>>;
    public createFaultReportV2(customerScope: string, faultReport?: FaultReport, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<FaultReport1>>;
    public createFaultReportV2(customerScope: string, faultReport?: FaultReport, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (customerScope === null || customerScope === undefined) {
            throw new Error('Required parameter customerScope was null or undefined when calling createFaultReportV2.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/faultreports`;
        return this.httpClient.request<FaultReport1>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: faultReport,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a Fault Report Document from an Incident
     * Removes a specified Fault Report document associated with a specific incident. Identified by its unique object key, the document is permanently deleted with no archiving or backup.
     * @param id Unique Incident UUID
     * @param objKey Unique Document Key
     * @param customerScope AGS or ZV
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDocumentByKeyV2(id: string, objKey: string, customerScope: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public deleteDocumentByKeyV2(id: string, objKey: string, customerScope: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public deleteDocumentByKeyV2(id: string, objKey: string, customerScope: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public deleteDocumentByKeyV2(id: string, objKey: string, customerScope: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteDocumentByKeyV2.');
        }
        if (objKey === null || objKey === undefined) {
            throw new Error('Required parameter objKey was null or undefined when calling deleteDocumentByKeyV2.');
        }
        if (customerScope === null || customerScope === undefined) {
            throw new Error('Required parameter customerScope was null or undefined when calling deleteDocumentByKeyV2.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/faultreports/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/documents/${this.configuration.encodeParam({name: "objKey", value: objKey, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a specific FaultReport
     * Deletes a specific FaultReport
     * @param id Fault Report UUID
     * @param customerScope AGS or ZV
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFaultreportByIdV2(id: string, customerScope: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public deleteFaultreportByIdV2(id: string, customerScope: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public deleteFaultreportByIdV2(id: string, customerScope: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public deleteFaultreportByIdV2(id: string, customerScope: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteFaultreportByIdV2.');
        }
        if (customerScope === null || customerScope === undefined) {
            throw new Error('Required parameter customerScope was null or undefined when calling deleteFaultreportByIdV2.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/faultreports/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all faultreports
     * Get a list of all faultreports for the LiOn frontend.
     * @param customerScope AGS or ZV
     * @param dateFrom Date From
     * @param dateTo Date To
     * @param houseNo House Number
     * @param incidentStatus List of Incident status
     * @param lightingNo Lighting Number
     * @param orderBy Order By
     * @param orderDir Order Direction
     * @param page Page
     * @param perPage Per Page
     * @param priority Priority
     * @param street Street
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllV2(customerScope: string, dateFrom?: string, dateTo?: string, houseNo?: string, incidentStatus?: Array<IncidentStatus>, lightingNo?: string, orderBy?: string, orderDir?: string, page?: number, perPage?: number, priority?: Priority, street?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<FaultReportList>;
    public getAllV2(customerScope: string, dateFrom?: string, dateTo?: string, houseNo?: string, incidentStatus?: Array<IncidentStatus>, lightingNo?: string, orderBy?: string, orderDir?: string, page?: number, perPage?: number, priority?: Priority, street?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<FaultReportList>>;
    public getAllV2(customerScope: string, dateFrom?: string, dateTo?: string, houseNo?: string, incidentStatus?: Array<IncidentStatus>, lightingNo?: string, orderBy?: string, orderDir?: string, page?: number, perPage?: number, priority?: Priority, street?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<FaultReportList>>;
    public getAllV2(customerScope: string, dateFrom?: string, dateTo?: string, houseNo?: string, incidentStatus?: Array<IncidentStatus>, lightingNo?: string, orderBy?: string, orderDir?: string, page?: number, perPage?: number, priority?: Priority, street?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (customerScope === null || customerScope === undefined) {
            throw new Error('Required parameter customerScope was null or undefined when calling getAllV2.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }
        if (dateFrom !== undefined && dateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateFrom, 'dateFrom');
        }
        if (dateTo !== undefined && dateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateTo, 'dateTo');
        }
        if (houseNo !== undefined && houseNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>houseNo, 'houseNo');
        }
        if (incidentStatus) {
            incidentStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'incidentStatus');
            })
        }
        if (lightingNo !== undefined && lightingNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lightingNo, 'lightingNo');
        }
        if (orderBy !== undefined && orderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderBy, 'orderBy');
        }
        if (orderDir !== undefined && orderDir !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderDir, 'orderDir');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'perPage');
        }
        if (priority !== undefined && priority !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>priority, 'priority');
        }
        if (street !== undefined && street !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>street, 'street');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/faultreports`;
        return this.httpClient.request<FaultReportList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all completed faultreports
     * Get a list of all faultreports for the LiOn frontend which are completed
     * @param customerScope AGS or ZV
     * @param dateFrom Date From
     * @param dateTo Date To
     * @param houseNo House Number
     * @param lightingNo Lighting Number
     * @param orderBy Order By
     * @param orderDir Order Direction
     * @param page Page
     * @param perPage Per Page
     * @param priority Priority
     * @param street Street
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompletedFaultReports(customerScope: string, dateFrom?: string, dateTo?: string, houseNo?: string, lightingNo?: string, orderBy?: string, orderDir?: string, page?: number, perPage?: number, priority?: Priority, street?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<FaultReportList>;
    public getCompletedFaultReports(customerScope: string, dateFrom?: string, dateTo?: string, houseNo?: string, lightingNo?: string, orderBy?: string, orderDir?: string, page?: number, perPage?: number, priority?: Priority, street?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<FaultReportList>>;
    public getCompletedFaultReports(customerScope: string, dateFrom?: string, dateTo?: string, houseNo?: string, lightingNo?: string, orderBy?: string, orderDir?: string, page?: number, perPage?: number, priority?: Priority, street?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<FaultReportList>>;
    public getCompletedFaultReports(customerScope: string, dateFrom?: string, dateTo?: string, houseNo?: string, lightingNo?: string, orderBy?: string, orderDir?: string, page?: number, perPage?: number, priority?: Priority, street?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (customerScope === null || customerScope === undefined) {
            throw new Error('Required parameter customerScope was null or undefined when calling getCompletedFaultReports.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }
        if (dateFrom !== undefined && dateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateFrom, 'dateFrom');
        }
        if (dateTo !== undefined && dateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateTo, 'dateTo');
        }
        if (houseNo !== undefined && houseNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>houseNo, 'houseNo');
        }
        if (lightingNo !== undefined && lightingNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lightingNo, 'lightingNo');
        }
        if (orderBy !== undefined && orderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderBy, 'orderBy');
        }
        if (orderDir !== undefined && orderDir !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderDir, 'orderDir');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'perPage');
        }
        if (priority !== undefined && priority !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>priority, 'priority');
        }
        if (street !== undefined && street !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>street, 'street');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/faultreports/completed`;
        return this.httpClient.request<FaultReportList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns location data of control cabinets.
     * Returns location data of all control cabinets in Luxdata filtered by customer scope and control cabinet number. This is used for rendering control cabinets on the map. The response uses our own, proprietary format.
     * @param customerScope 
     * @param cabinetNo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getControlCabinetGeoLocationsV2(customerScope: string, cabinetNo?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<GeoLocationResponseV2ControlCabinetLocationDataV2>;
    public getControlCabinetGeoLocationsV2(customerScope: string, cabinetNo?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<GeoLocationResponseV2ControlCabinetLocationDataV2>>;
    public getControlCabinetGeoLocationsV2(customerScope: string, cabinetNo?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<GeoLocationResponseV2ControlCabinetLocationDataV2>>;
    public getControlCabinetGeoLocationsV2(customerScope: string, cabinetNo?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (customerScope === null || customerScope === undefined) {
            throw new Error('Required parameter customerScope was null or undefined when calling getControlCabinetGeoLocationsV2.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (cabinetNo !== undefined && cabinetNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cabinetNo, 'cabinetNo');
        }
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/controlcabinets/search`;
        return this.httpClient.request<GeoLocationResponseV2ControlCabinetLocationDataV2>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Incident\&#39;s Fault Report Documents
     * Provides a collection of Fault Report documents associated with a specific incident, identified by its unique ID
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentsByIncidentIdV2(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<IncidentDocumentCollectionTO>;
    public getDocumentsByIncidentIdV2(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<IncidentDocumentCollectionTO>>;
    public getDocumentsByIncidentIdV2(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<IncidentDocumentCollectionTO>>;
    public getDocumentsByIncidentIdV2(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDocumentsByIncidentIdV2.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/faultreports/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/documents`;
        return this.httpClient.request<IncidentDocumentCollectionTO>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a specific FaultReport
     * Get a specific FaultReport
     * @param id Fault Report UUID
     * @param customerScope AGS or ZV
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFaultreportByIdV2(id: string, customerScope: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<FaultReport1>;
    public getFaultreportByIdV2(id: string, customerScope: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<FaultReport1>>;
    public getFaultreportByIdV2(id: string, customerScope: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<FaultReport1>>;
    public getFaultreportByIdV2(id: string, customerScope: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getFaultreportByIdV2.');
        }
        if (customerScope === null || customerScope === undefined) {
            throw new Error('Required parameter customerScope was null or undefined when calling getFaultreportByIdV2.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/faultreports/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<FaultReport1>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns lighting points in GeoJSON format
     * Returns all lighting points which match the given filters. The response is in GeoJSON format for rendering on the Mapbox map.
     * @param customerScope An optional customer scope to be used as filter
     * @param lightingNo A valid lighting point number or parts of it
     * @param maxFeatures max. features to be loaded; if not provided, a maximum of 10000 features is loaded
     * @param neLatitude Latitude of the north-east corner of the bounding box
     * @param neLongitude Longitude of the north-east corner of the bounding box
     * @param startIndex Index for pagination in combination with maxFeatures
     * @param swLatitude Latitude of the south-west corner of the bounding box
     * @param swLongitude Longitude of the south-west corner of the bounding box
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGeoJsonV2(customerScope?: string, lightingNo?: string, maxFeatures?: number, neLatitude?: number, neLongitude?: number, startIndex?: number, swLatitude?: number, swLongitude?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<FeatureCollectionV2>;
    public getGeoJsonV2(customerScope?: string, lightingNo?: string, maxFeatures?: number, neLatitude?: number, neLongitude?: number, startIndex?: number, swLatitude?: number, swLongitude?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<FeatureCollectionV2>>;
    public getGeoJsonV2(customerScope?: string, lightingNo?: string, maxFeatures?: number, neLatitude?: number, neLongitude?: number, startIndex?: number, swLatitude?: number, swLongitude?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<FeatureCollectionV2>>;
    public getGeoJsonV2(customerScope?: string, lightingNo?: string, maxFeatures?: number, neLatitude?: number, neLongitude?: number, startIndex?: number, swLatitude?: number, swLongitude?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }
        if (lightingNo !== undefined && lightingNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lightingNo, 'lightingNo');
        }
        if (maxFeatures !== undefined && maxFeatures !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxFeatures, 'maxFeatures');
        }
        if (neLatitude !== undefined && neLatitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>neLatitude, 'neLatitude');
        }
        if (neLongitude !== undefined && neLongitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>neLongitude, 'neLongitude');
        }
        if (startIndex !== undefined && startIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startIndex, 'startIndex');
        }
        if (swLatitude !== undefined && swLatitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>swLatitude, 'swLatitude');
        }
        if (swLongitude !== undefined && swLongitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>swLongitude, 'swLongitude');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/lightingpoints/geo-json`;
        return this.httpClient.request<FeatureCollectionV2>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all in progress faultreports
     * Get a list of all faultreports for the LiOn frontend which are currently in progress
     * @param customerScope AGS or ZV
     * @param dateFrom Date From
     * @param dateTo Date To
     * @param houseNo House Number
     * @param lightingNo Lighting Number
     * @param orderBy Order By
     * @param orderDir Order Direction
     * @param page Page
     * @param perPage Per Page
     * @param priority Priority
     * @param street Street
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInProgressFaultReports(customerScope: string, dateFrom?: string, dateTo?: string, houseNo?: string, lightingNo?: string, orderBy?: string, orderDir?: string, page?: number, perPage?: number, priority?: Priority, street?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<FaultReportList>;
    public getInProgressFaultReports(customerScope: string, dateFrom?: string, dateTo?: string, houseNo?: string, lightingNo?: string, orderBy?: string, orderDir?: string, page?: number, perPage?: number, priority?: Priority, street?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<FaultReportList>>;
    public getInProgressFaultReports(customerScope: string, dateFrom?: string, dateTo?: string, houseNo?: string, lightingNo?: string, orderBy?: string, orderDir?: string, page?: number, perPage?: number, priority?: Priority, street?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<FaultReportList>>;
    public getInProgressFaultReports(customerScope: string, dateFrom?: string, dateTo?: string, houseNo?: string, lightingNo?: string, orderBy?: string, orderDir?: string, page?: number, perPage?: number, priority?: Priority, street?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (customerScope === null || customerScope === undefined) {
            throw new Error('Required parameter customerScope was null or undefined when calling getInProgressFaultReports.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }
        if (dateFrom !== undefined && dateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateFrom, 'dateFrom');
        }
        if (dateTo !== undefined && dateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateTo, 'dateTo');
        }
        if (houseNo !== undefined && houseNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>houseNo, 'houseNo');
        }
        if (lightingNo !== undefined && lightingNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lightingNo, 'lightingNo');
        }
        if (orderBy !== undefined && orderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderBy, 'orderBy');
        }
        if (orderDir !== undefined && orderDir !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderDir, 'orderDir');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'perPage');
        }
        if (priority !== undefined && priority !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>priority, 'priority');
        }
        if (street !== undefined && street !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>street, 'street');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/faultreports/inprogress`;
        return this.httpClient.request<FaultReportList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns location data for lighting points within the specified bounds.
     * Returns the location data of all lighting points which lie in the specified bounding box. The bounding box has to be specified by the SW and the NE corner. The response uses our own, proprietary format.
     * @param ceLatitude Deprecated. The latitude of the center
     * @param ceLongitude Deprecated. The longitude of the center
     * @param customerScope The customer scope
     * @param lightingNo The number of the lighting point
     * @param neLatitude The latitude of the North-East corner
     * @param neLongitude The longitude of the North-East corner
     * @param swLatitude The latitude of the South-West corner
     * @param swLongitude The longitude of the South-West corner
     * @param zoom Deprecated. The zoom level
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLightingPointsGeoLocationsByBoundsV2(ceLatitude?: number, ceLongitude?: number, customerScope?: string, lightingNo?: string, neLatitude?: number, neLongitude?: number, swLatitude?: number, swLongitude?: number, zoom?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<GeoLocationResponseV2LightingPointLocationDataV2>;
    public getLightingPointsGeoLocationsByBoundsV2(ceLatitude?: number, ceLongitude?: number, customerScope?: string, lightingNo?: string, neLatitude?: number, neLongitude?: number, swLatitude?: number, swLongitude?: number, zoom?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<GeoLocationResponseV2LightingPointLocationDataV2>>;
    public getLightingPointsGeoLocationsByBoundsV2(ceLatitude?: number, ceLongitude?: number, customerScope?: string, lightingNo?: string, neLatitude?: number, neLongitude?: number, swLatitude?: number, swLongitude?: number, zoom?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<GeoLocationResponseV2LightingPointLocationDataV2>>;
    public getLightingPointsGeoLocationsByBoundsV2(ceLatitude?: number, ceLongitude?: number, customerScope?: string, lightingNo?: string, neLatitude?: number, neLongitude?: number, swLatitude?: number, swLongitude?: number, zoom?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (ceLatitude !== undefined && ceLatitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ceLatitude, 'ceLatitude');
        }
        if (ceLongitude !== undefined && ceLongitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ceLongitude, 'ceLongitude');
        }
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }
        if (lightingNo !== undefined && lightingNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lightingNo, 'lightingNo');
        }
        if (neLatitude !== undefined && neLatitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>neLatitude, 'neLatitude');
        }
        if (neLongitude !== undefined && neLongitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>neLongitude, 'neLongitude');
        }
        if (swLatitude !== undefined && swLatitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>swLatitude, 'swLatitude');
        }
        if (swLongitude !== undefined && swLongitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>swLongitude, 'swLongitude');
        }
        if (zoom !== undefined && zoom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>zoom, 'zoom');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/lightingpoints/by-bounds`;
        return this.httpClient.request<GeoLocationResponseV2LightingPointLocationDataV2>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns location data of lighting points.
     * Returns location data of all lighting points in Luxdata filtered by customer scope and lighting point number. This is used for rendering lighting points on the map. The response uses our own,proprietary format.
     * @param customerScope 
     * @param lightingNo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLightingPointsGeoLocationsV2(customerScope: string, lightingNo?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<GeoLocationResponseV2LightingPointLocationDataV2>;
    public getLightingPointsGeoLocationsV2(customerScope: string, lightingNo?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<GeoLocationResponseV2LightingPointLocationDataV2>>;
    public getLightingPointsGeoLocationsV2(customerScope: string, lightingNo?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<GeoLocationResponseV2LightingPointLocationDataV2>>;
    public getLightingPointsGeoLocationsV2(customerScope: string, lightingNo?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (customerScope === null || customerScope === undefined) {
            throw new Error('Required parameter customerScope was null or undefined when calling getLightingPointsGeoLocationsV2.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }
        if (lightingNo !== undefined && lightingNo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lightingNo, 'lightingNo');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/lightingpoints/search`;
        return this.httpClient.request<GeoLocationResponseV2LightingPointLocationDataV2>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to determine summary of a customer\&#39;s FaultReport.
     * Show summary for a customer
     * @param customerScope AGS or ZV
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSummaryV2(customerScope: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<FaultReportSummary>;
    public getSummaryV2(customerScope: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<FaultReportSummary>>;
    public getSummaryV2(customerScope: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<FaultReportSummary>>;
    public getSummaryV2(customerScope: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (customerScope === null || customerScope === undefined) {
            throw new Error('Required parameter customerScope was null or undefined when calling getSummaryV2.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/faultreports/summary`;
        return this.httpClient.request<FaultReportSummary>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new unqualified FaultReport for LiOn
     * Create a new unqualified FaultReport for LiOn
     * @param incidentNotification 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveUnQualifiedFaultReportV2(incidentNotification?: IncidentNotification, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public saveUnQualifiedFaultReportV2(incidentNotification?: IncidentNotification, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public saveUnQualifiedFaultReportV2(incidentNotification?: IncidentNotification, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public saveUnQualifiedFaultReportV2(incidentNotification?: IncidentNotification, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/unqualifiedfaultreports`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: incidentNotification,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Confirm or reject an unqualified FaultReport.
     * LiOn User can forward (confirm) or reject a FaultReport received through the Bürgerformular.
     * @param id Fault Report UUID
     * @param customerScope AGS or ZV
     * @param approvalStateTOV2 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setApprovalStateV2(id: string, customerScope: string, approvalStateTOV2?: ApprovalStateTOV2, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public setApprovalStateV2(id: string, customerScope: string, approvalStateTOV2?: ApprovalStateTOV2, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public setApprovalStateV2(id: string, customerScope: string, approvalStateTOV2?: ApprovalStateTOV2, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public setApprovalStateV2(id: string, customerScope: string, approvalStateTOV2?: ApprovalStateTOV2, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setApprovalStateV2.');
        }
        if (customerScope === null || customerScope === undefined) {
            throw new Error('Required parameter customerScope was null or undefined when calling setApprovalStateV2.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerScope !== undefined && customerScope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerScope, 'customerScope');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (AwsApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('AwsApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        // authentication (BearerAuth) required
        localVarCredential = this.configuration.lookupCredential('BearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/faultreports/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/approvalState`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                body: approvalStateTOV2,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
