import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { User } from '../../shared/common/models/user';
import {
  loginFailureResponse,
  loginStateCreateAction,
  loginSuccessResponse,
  logoutAction,
  resetLoginErrorAction,
  tokenRefreshSuccessResponse, updateTokenData,
} from './auth.actions';

export interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  errors: string[];
  hasLoginError: boolean;
  loginState: string;
}

export const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  errors: [],
  hasLoginError: false,
  loginState: null,
};

const getAuthFeatureState = createFeatureSelector<AuthState>('auth');
export const isAuthenticatedSelector = createSelector(
  getAuthFeatureState,
  state => state.isAuthenticated
);
export const hasLoginErrorSelector = createSelector(
  getAuthFeatureState,
  state => state.hasLoginError
);
export const loggedInUserSelector = createSelector(
  getAuthFeatureState,
  state => state.user
);
export const loginStateSelector = createSelector(
  getAuthFeatureState,
  state => state.loginState
);

export const reducer = createReducer<AuthState>(
  initialState,
  on(loginSuccessResponse, (state, action): AuthState => {
    return {
      ...state,
      isAuthenticated: true,
      user: action.user,
      errors: [],
      hasLoginError: false,
    };
  }),
  on(loginFailureResponse, (state, action): AuthState => {
    return {
      ...initialState,
      isAuthenticated: false,
      user: null,
      errors: action.errors,
      hasLoginError: true,
    };
  }),
  on(logoutAction, (state, action): AuthState => {
    return initialState;
  }),
  on(resetLoginErrorAction, (state, action): AuthState => {
    return {
      ...state,
      errors: [],
      hasLoginError: false,
    };
  }),
  on(loginStateCreateAction, (state, action): AuthState => {
    return {
      ...state,
      loginState: action.loginState,
    };
  }),
  on(tokenRefreshSuccessResponse, (state, action): AuthState => {
    return {
      ...state,
      isAuthenticated: true,
      user: action.user,
      errors: [],
      hasLoginError: false,
    };
  }),
  on(updateTokenData, (state, action): AuthState => {
    return {
      ...state,
      user: action.user,
    };
  }),
);
export function authReducer(state: AuthState | undefined, action: Action) {
  return reducer(state, action);
}
