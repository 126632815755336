import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { EnvironmentService } from 'app/shared/common/services/env.service';
import { environment } from 'environments/environment';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { AppState } from './app-state';
import { User } from './shared/common/models/user';
import { logoutAction } from './auth/state/auth.actions';
import { isAuthenticatedSelector, loggedInUserSelector } from './auth/state/auth.reducers';
import { VersionInfoComponent } from './shared/common/components/version-info/version-info.component';
import { NavigationService } from './shared/common/services/navigation.service';
import { UserRoleUtils } from './shared/common/services/user.role.utils';
import { loadNetAreaRequest, loadSystemUsersRequest, loadUserSettingsRequest } from './shared/state/shared.actions';
import { DataHoldingService } from './shared/common/services/data-holding-service/data-holding.service';
import { PlannedMaintenanceTO } from './shared/api/generated/v2/incident';
import { PlannedMaintenanceService } from './shared/common/services/planned-maintenance.service';
import { MaintenanceDialogComponent } from './shared/common/components/maintenance-dialog/maintenance-dialog.component';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import { RbacGuard } from './shared/common/guards/rbac.guard';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public isAuthenticated = false;
  public loggedInUser: User;
  public supportEmail: string =
    EnvironmentService.getEnvironment().supportEmail;
  public UserRoleUtils = UserRoleUtils;
  environment: 'Staging' | 'Development' | 'Local';
  readonly COMMIT_HASH = environment.sourceVersion;
  readonly BUILD_ID = environment.buildId;
  maintenance: PlannedMaintenanceTO;
  private readonly routerEventsSubscription: Subscription;
  copyrightYear: number = new Date().getFullYear();

  constructor(
    protected sanitizer: DomSanitizer,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private logger: NGXLogger,
    // only to init subscription
    private navigationService: NavigationService,
    private iconRegistry: MatIconRegistry,
    private dataHoldingService: DataHoldingService,
    private maintenanceService: PlannedMaintenanceService,
    private router: Router,
    private swUpdate: SwUpdate
  ) {
    if (EnvironmentService.isStage()) { this.environment = 'Staging'; }
    else if (EnvironmentService.isDev()) { this.environment = 'Development'; }
    else if (EnvironmentService.isLocal()) { this.environment = 'Local'; }

    this.iconRegistry.addSvgIcon(
      'archive',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/archive_icon.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'netze-logo',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/netze-bw-logo.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'group77',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/group77.svg')
    );
    this.iconRegistry.addSvgIcon(
      'group78',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/group78.svg')
    );

    this.routerEventsSubscription = this.router.events.pipe(
      filter(event =>
        event instanceof NavigationStart
      )
    ).subscribe((event) => this.checkForMaintenance());

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        if (event.type === 'VERSION_READY') {
          const reload = confirm('Eine neue Version der Applikation ist verfügbar. Jetzt aktualisieren?');
          if (reload) {
            window.location.reload();
          }
        }
      });
    }
  }

  public ngOnInit(): void {
    this.store
      .select(isAuthenticatedSelector)
      .subscribe((isAuthenticated: boolean) => {
        this.isAuthenticated = isAuthenticated;
        if (!!isAuthenticated) {
          this.store.dispatch(loadNetAreaRequest());
        }
      });
    this.store.select(loggedInUserSelector).subscribe((user: User) => {
      this.loggedInUser = user;
      if (!!this.loggedInUser) {
        this.store.dispatch(
          loadSystemUsersRequest({ internal: !!this.loggedInUser.internal })
        );
        this.store.dispatch(
          loadUserSettingsRequest({
            userId: UserRoleUtils.getUserId(this.loggedInUser),
            internal: !!this.loggedInUser.internal,
          })
        );
      }
    });
  }

  public IsSnowVisible() {
    // should be true between 07.12.2023 and 05.01.2024
    const today = new Date();
    const start = new Date(2023, 11, 6);
    const end = new Date(2024, 0, 5);
    return start <= today && today <= end;
  }

  public getCurrentRoute(): string {
    return this.navigationService.currentUrl;
  }

  public openInfo(): void {
    const dialogRef = this.dialog.open(VersionInfoComponent, {
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.logger.trace('version info dialog closed with result=', result);
    });
  }

  checkForMaintenance() {
    if (this.isAuthenticated) {
      this.maintenanceService.getPlannedMaintenances().subscribe(
        plannedMaintenance => {
          this.maintenance = this.maintenanceService.getCurrentMaintenance(plannedMaintenance, new Date());
          if (this.maintenance) {
            this.openDialog();
          }
        }
      );
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(MaintenanceDialogComponent, {
      disableClose: true,
      data: this.maintenance
    });
  }

  public logout(): void {
    this.store.dispatch(logoutAction());
  }

  ngOnDestroy(): void {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }
}
