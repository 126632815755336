/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Vollständige Störungsmeldung mit technischen Daten zur Weiterleitung an Dienstleister
 */
export interface SupplierMailTO { 
    /**
     * Mail Empfänger
     */
    recipients?: Array<string>;
    /**
     * CC Mail Empfänger
     */
    recipientCCs?: Array<string>;
    /**
     * Mail Empfänger Name
     */
    recipientName?: string;
    /**
     * Mail Replyto Adresse
     */
    replyTo?: string;
    /**
     * Mail Replyto Name
     */
    replyToName?: string;
    /**
     * Optional. Subject of email
     */
    subject?: string;
    /**
     * Störungs UUID
     */
    uuid: string;
    /**
     * UUID des Auftrags
     */
    orderUuid: string;
    /**
     * Vollständige Leuchtstellennummer.
     */
    lightingNo?: string;
    /**
     * Vollständige Schaltschranknummer.
     */
    controlCabinetNo?: string;
    /**
     * System Störungstyp
     */
    systemType: string;
    /**
     * Zeitstempel der Erfassung
     */
    reportingTimestamp?: string;
    /**
     * Behebung bis Zeitpunkt der Störung
     */
    priorityDueDateTime?: string;
    /**
     * Priorität der Störungsmeldung
     */
    priority?: string;
    /**
     * Symptome der Störungsmeldung
     */
    symptoms?: Array<string>;
    /**
     * Symptom Beschreibung für Sonstige
     */
    symptomOther?: string;
    /**
     * Straße der Störungsmeldung
     */
    street?: string;
    /**
     * HausNr der Störungsmeldung
     */
    houseNo?: string;
    /**
     * PLZ der Störungsmeldung
     */
    zipCode?: string;
    /**
     * Netzgebiet
     */
    netArea?: string;
    /**
     * Ort der Störungsmeldung
     */
    city?: string;
    /**
     * Teilort der Störungsmeldung
     */
    cityDistrict?: string;
    /**
     * Weitere Anmerkungen zum Ort der Störungsmeldung
     */
    furtherRemarks?: string;
    /**
     * Purchased order number
     */
    purchaseOrderNumber?: string;
    /**
     * Purchased material
     */
    purchaseMaterial?: string;
    /**
     * Resolution date of the material order
     */
    wishResolutionDate?: string;
    /**
     * Anmerkungen des Bürgers
     */
    remarksCitizen?: string;
    /**
     * Weitere Anmerkungen der Kommune
     */
    remarksMunicipality?: string;
    /**
     * Zusätzlicher Mailtext
     */
    mailText?: string;
    /**
     * Object Keys der Dokumente, die mitgeschickt werden sollen
     */
    docObjectKeys?: Array<string>;
    /**
     * Arms
     */
    arms?: string;
    /**
     * Poles
     */
    poles?: string;
    /**
     * Luminaires
     */
    luminaires?: string;
    /**
     * Lamps
     */
    lamps?: string;
    /**
     * Cable Number
     */
    cableNumber?: string;
    /**
     * Control Cabinet Number
     */
    controlCabinetNumber?: string;
    /**
     * Control Cabinet Description
     */
    controlCabinetDescription?: string;
    /**
     * Trigger Description
     */
    triggerDescription?: string;
    /**
     * Control Cabinet Type
     */
    controlCabinetType?: string;
    /**
     * Is a warning system mounted to the pole?
     */
    warningSystemPresent?: boolean;
    /**
     * WFM Order number
     */
    wfmOrderNumber?: string;
    /**
     * Minze NA SAP number
     */
    minzeNaNumber?: string;
    /**
     * Minze NC SAP number
     */
    minzeNcNumber?: string;
    /**
     * Minze ND SAP number
     */
    minzeNdNumber?: string;
}

