import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Spinner } from 'app/shared/common/components/spinner-modal/spinner';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, share, switchMap, take, takeUntil } from 'rxjs/operators';
import { User } from '../../shared/common/models/user';
import jwt_decode from 'jwt-decode';
import { AuthUrlUtils } from './auth.url.utils';
import { UserRoleUtils } from 'app/shared/common/services/user.role.utils';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app-state';
import { loggedInUserSelector } from '../state/auth.reducers';
import { TokenData } from '../../shared/common/models/tokenData';
import { LoginResultModalDialogDataImpl } from '../components/login-result-modal/login-result-modal-dialog.data.impl';
import { loginFailureResponse, logoutAction, resetLoginErrorAction, updateTokenData } from '../state/auth.actions';
import { SessionExpiredDialogComponent } from '../../shared/common/components/session-expired-dialog/session-expired-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class TokenService implements OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(private httpClient: HttpClient,
              private httpBackend: HttpBackend,
              private dialog: MatDialog,
              private store: Store<AppState>,
              private logger: NGXLogger,
  ) {
      // avoid using default http interceptor
      this.httpClient = new HttpClient(httpBackend);
  }

  public getRefreshToken(): Observable<string> {
    return this.store.select(loggedInUserSelector).pipe(
      take(1),
      map(user => user?.tokenData?.refresh_token)
    );
  }

  public retrieveTokenByCode(code: string): Observable<User> {
    const body = this.buildTokenRequestBody({
      grant_type: 'authorization_code',
      client_id: AuthUrlUtils.getAuthClientId(),
      code,
      redirect_uri: AuthUrlUtils.getRedirectAbsoluteUrl(),
    });
    return this.performTokenRequest(body, false);
  }

  public retrieveTokenByRefreshToken(refreshToken: string): Observable<User> {
    const body = this.buildTokenRequestBody({
      grant_type: 'refresh_token',
      client_id: AuthUrlUtils.getAuthClientId(),
      refresh_token: refreshToken,
      redirect_uri: AuthUrlUtils.getRedirectAbsoluteUrl(),
    });
    return this.performTokenRequest(body, true);
  }

  private performTokenRequest(body: URLSearchParams, isRefresh: boolean): Observable<User> {
    const hdrs: HttpHeaders = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const tokenRequest$
      = this.httpClient.post(AuthUrlUtils.getTokenAbsoluteUrl(), body.toString(),
      {
        headers: hdrs,
        observe: 'response'
      })
    .pipe(
      share(),
      takeUntil(this.destroy$),
      switchMap(response => {
        if (response.status !== 200) {
          throw new Error('Unexpected response status');
        }
        return this.handleResponse(true, response.body);
      }),
      catchError(error => {
        this.logger.trace('Token request failed:', error);
        if (isRefresh) {
          this.store.dispatch(logoutAction());
          this.openSessionExpiredDialog();
        } else {
          this.handleTokenRequestFailure(error);
        }
        return throwError(() => new Error('Authentication failed'));
      })
    );

    new Spinner().spin(tokenRequest$, this.dialog, { message: 'Benutzer authentifizieren...' });

    return tokenRequest$;
  }

  private handleResponse(success: boolean, body?: any): Observable<User> {
    if (!success) {
      return throwError(() => new Error('Authentication failed'));
    }
    return this.createUserFromToken(body);
  }

  private createUserFromToken(body: any): Observable<User> {
    return this.createTokenData(body).pipe(
      map((tokenData) => {
        const user: User = this.tokenToUserData(body.id_token);
        user.tokenData = tokenData;
        this.logger.trace('user data=', user);
        this.store.dispatch(updateTokenData({ user }));
        return user;
      })
    );
  }

  private createTokenData(body: any): Observable<TokenData> {
    return this.store.select(loggedInUserSelector).pipe(
      take(1),
      map((existingUser) => {
        const existing = existingUser?.tokenData;
        if (!existing) {
          return { ...body };
        }

        return {
          refresh_token: existing.refresh_token,
          access_token: body.access_token,
          id_token: body.id_token,
          expires_in: body.expires_in,
          token_type: body.token_type
        };
      })
    );
  }

  private tokenToUserData(encodedToken: string): User {
    const decodedToken: any = this.decodeToken(encodedToken);
    const user: User = new User();

    user.id = decodedToken.email || decodedToken['cognito:username'];
    user.username = decodedToken.name || decodedToken.preferred_username || decodedToken.email;
    user.email = decodedToken.email;
    user.firstname = decodedToken.given_name || '';
    user.lastname = decodedToken.family_name || '';
    user.roles = UserRoleUtils.getUserRolesFromToken(decodedToken['custom:roles']);
    user.primaryRole = UserRoleUtils.determinePrimaryUserRoleInfo(user.roles);

    // in case firstname and lastname could not be determined
    // try to parse the token in another way
    if (!user.firstname && !user.lastname && user.username) {
      const [lastname, firstname] = user.username.split(' ');
      user.lastname = lastname || '';
      user.firstname = firstname || '';
    }

    return user;
  }

  private decodeToken(encodedToken: string): any {
    return jwt_decode(encodedToken);
  }

  private handleTokenRequestFailure(error: any): void {
    LoginResultModalDialogDataImpl.openAuthenticationFailedResultDialog(this.dialog);
    this.store.dispatch(loginFailureResponse({ errors: [error] }));
    this.store.dispatch(resetLoginErrorAction());
  }

  private buildTokenRequestBody(params: Record<string, string>): URLSearchParams {
    const body = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => body.set(key, value));
    return body;
  }

  private openSessionExpiredDialog() {
    const dialogRef = this.dialog.open(SessionExpiredDialogComponent, {
      disableClose: false
    });
    dialogRef.afterClosed().subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
