/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncidentData } from './incidentData';
import { IncidentStatus } from './incidentStatus';
import { NetArea } from './netArea';


export interface PropertiesV2 { 
    id?: string;
    assetId?: string;
    longitude?: number;
    latitude?: number;
    number?: string;
    state?: string;
    cabinetNumber?: string;
    city?: string;
    cityDistrict?: string;
    street?: string;
    houseNumber?: string;
    zipCode?: string;
    locationNumber1?: string;
    customerScope?: string;
    netArea?: NetArea;
    incidentStatus?: IncidentStatus;
    incident?: IncidentData;
    lastCompletedDate?: string;
}
export namespace PropertiesV2 {
}


