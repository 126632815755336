import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app-state';
import { UserRoleUtils } from 'app/shared/common/services/user.role.utils';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { User } from '../../shared/common/models/user';
import { isAuthenticatedSelector, loggedInUserSelector } from '../state/auth.reducers';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private logger: NGXLogger
  ) {
    // check periodically isAuthentaticated state
    setInterval(
      () => {
        this.store.select(isAuthenticatedSelector).subscribe((isAuthenticated: boolean) => {
          if (!isAuthenticated) {
            this.router.navigate(['/auth/login']);
          }
        }, () => {
          this.router.navigate(['/auth/login']);
        });
      },
      5000
    );
  }
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    this.logger.trace('canActivate', route, state);

    if (state.url.startsWith('/auth')) {
      return true;
    }

    if (state.url.startsWith('/incident/create/new')) {
      return this.checkUserRole(UserRoleUtils.hasRoleOperatorOrOperatorExternOrUser, '/incident/list');
    }

    if (state.url.startsWith('/shared/settings')) {
      return this.checkUserRole(UserRoleUtils.hasRoleOperator, '/incident/list');
    }

    // Default authentication check
    return this.store.select(isAuthenticatedSelector).pipe(
      map((isAuthenticated: boolean) => isAuthenticated || this.redirectToLogin()),
      catchError(() => of(this.redirectToLogin()))
    );
  }

  private checkUserRole(roleCheckFn: (user: User) => boolean, redirectUrl: string): Observable<boolean | UrlTree> {
    return this.store.select(loggedInUserSelector).pipe(
      map((user: User) => {
        const hasRole = roleCheckFn(user);
        return hasRole || this.router.parseUrl(redirectUrl);
      }),
      catchError(() => of(this.redirectToLogin()))
    );
  }

  private redirectToLogin(): boolean | UrlTree {
    this.router.navigate(['/auth/login']);
    return false;
  }
}
